
.back-button {
  margin-bottom:8px;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px; /* Optional: add rounded corners */
}
.hi{
  color:white;
}
button {
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

button:hover {
  background-color: #f1f1f1; /* Light background color on hover */
  color: #333; /* Darker text color on hover */
}
/* Container for the video player */
.plr {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #000;
  padding: 0; /* Remove padding if it causes overflow */
  width: 100%; /* Full width */
  height: 50vh; /* Adjust height as necessary */
  max-width: 100%; /* Ensure it doesn’t exceed viewport width */
  max-height: 100vh; /* Ensure it doesn’t exceed viewport height */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  overflow: hidden; /* Hide any overflow */
  display: flex; /* Flexbox centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-top: 5px;
}

@media (max-width: 768px) {
  .plr {
    width: 100%; /* Reduce width on mobile */
    height: 40vh; /* Adjust height for smaller screens */
    max-width: 100%; /* Ensure it doesn’t exceed viewport width */
    max-height: 100vh; /* Ensure it doesn’t exceed viewport height */
    margin: 0 auto; /* Center horizontally */
  }
}




/* Loading Spinner */
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure it's on top of other elements */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #007bff;
  background-color: transparent !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.video-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Optional background */
  opacity: 1;
  transition: opacity 0.3s;
  z-index: 10;
}

.video-thumbnail.hidden {
  opacity: 0; /* Hide the thumbnail when the video starts playing */
  pointer-events: none; /* Prevent interaction with the hidden thumbnail */
}

.video-thumbnail.visible {
  opacity: 1;
}

.video-thumbnail img {
  max-width: 100%;
  max-height: 100%;
}

.video-thumbnail.hidden {
  opacity: 0; /* Hide the thumbnail when the video starts playing */
  pointer-events: none; /* Prevent interaction with the hidden thumbnail */
}

.video-thumbnail.visible {
  opacity: 1;
}

.video-thumbnail img {
  max-width: 100%;
  max-height: 100%;
}
